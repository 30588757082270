// form style
form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
// input[type='date'],
textarea {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  padding: $input-btn-padding-y $input-btn-padding-x;
  font-size: $input-font-size;
  line-height: $input-btn-line-height;
  font-family: $input-font-family;
  box-sizing: border-box;
  border: $input-border-width solid $input-border-color;
  border-radius: 0;
  color: $input-color;
  background: $input-bg;
  border-radius: 4px;

  @include media('≥tablet') {
    padding-left: rem-calc(21);
    padding-right: rem-calc(10);
    font-size: rem-calc(14);
  }

  &:not(textarea) {
    height: $input-height;
  }

  &:focus {
    outline: none;
  }

  @include placeholder {
    color: $input-placeholder-color;
    font-size: rem-calc(12);  
    line-height: 1.5;

    @include media('≥tablet') { 
      font-size: rem-calc(16);
      line-height: 1; 
    }
  }
}

input[type='search']::-webkit-search-cancel-button {-webkit-appearance: none;}

// input[type="search"] {
//   -webkit-appearance: textfield;
// }

textarea {
  resize: vertical;
  vertical-align: top;
  height:  rem-calc(144);  
  resize: none;
}




.form-row {
  margin: rem-calc(0 0 17);

  @include media('≥tablet') { 
    margin:  rem-calc(0 0 25);  
  }

  &:last-child{ 
    margin-bottom: 0; 
  }
}

.hold-form {
  background-color: #f4f4f4;
  padding: rem-calc(20 25);

  @include media('≥tablet') {
    padding: rem-calc(30);
  }

  h3 {
    margin-bottom: rem-calc(30);  
  }

  label {
    text-transform: uppercase;
    line-height: rem-calc(16);
    font-size: rem-calc(13);  
    margin-bottom: rem-calc(12);  

    @include media('≥tablet') {
      line-height: rem-calc(21);
      font-size: rem-calc(17);
    }
  }
}
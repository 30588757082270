

// Colors
// 
$black:                             #000;
$white:                             #ffffff !default;
$brown:                             #4a4a4a;
$gray:                              #f4f4f4 !default;
$gray-100: 			                #f8f9fa !default;
$gray-200: 			                #e9ecef !default;
$gray-300: 			                #dee2e6 !default;
$gray-400: 				            #ced4da !default;
$gray-500: 				            #adb5bd !default;
$gray-600: 					        #868e96 !default;
$gray-700: 				            #495057 !default;
$gray-800: 					        #343a40 !default;
$gray-900: 				            #212529 !default;


$blue:                              #007bff !default;
$indigo:                            #6610f2 !default;
$purple:                            #6f42c1 !default;
$pink:                              #e83e8c !default;
$red:                               #dc3545 !default;
$orange:                            #fd7e14 !default;
$yellow:                            #ffc107 !default;
$green:                             #1bc66f !default;
$teal:                              #20c997 !default;
$cyan:                              #17a2b8 !default;


$primary:                           $blue !default;
$secondary:                         $gray-600 !default;
$success:                           $green !default;
$info:                              $cyan !default;
$warning:                           $yellow !default;
$danger:                            $red !default;
$light:                             $white !default;
$dark:                              $gray-800 !default;


// Grid gutter
//
$grid-gutter-width:                 20px;

// Width container
//  
$min-width-container:               320px;
$max-width-container:               910px;

$border-width:                      1px;
$border-color:                      #979797;
$body-color:                        $brown;


// Font style
// 
$font-family-base:                  'Roboto Mono', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif !default;  

$font-size-base:                    .75rem !default;

$line-height:			            1.5 !default;

$line-height-base:                  $line-height;

// links
// 
$link-color: 						$green;


// Navbar

$navbar-light-color:                 $green !default;
$navbar-light-hover-color:           #128349 !default;
$navbar-light-active-color:          #128349 !default;
$navbar-light-disabled-color:        rgba($green, .3) !default;


// wp-reset.scss - variables
// 
$widget-margin:                     rem-calc(20px);
$vertical-rhythm:                   rem-calc(20px);
$wp-caption-border:                 $border-width solid $border-color;

// form-style.scss - variables
//
$input-font-family:                 $font-family-base;
$input-font-size:                   $font-size-base;
$input-btn-padding-y:               0.9375rem;
$input-btn-padding-x:               1.375rem;
$input-btn-line-height: 			      $line-height-base !default;
$input-border-width:                0.0625rem !default;
$input-border-color:                rgba(#979797, 0.31) !default;
$input-color:                       $black !default;
$input-bg:                          $white !default;
$input-height:                      rem-calc(51px);
$input-placeholder-color:           #ddd;

//  Buttons
//  
$input-btn-padding-y:               .5rem !default;
$input-btn-padding-x:               .5rem !default;


// Fonts
$h1-font-size:                1.813rem !default;
$h2-font-size:                1.25rem !default;
$h3-font-size:                1rem !default;

//cards
$card-border-radius:           0.375rem;
$card-border-color:            transparent;
$card-spacer-x:                0.625rem;
$card-spacer-y:                0.9375rem;


//jumbotron
$jumbotron-bg:                 $green;
$border-radius-lg:             0.375rem;


// Modals

$modal-md:                     935px !default;
$modal-backdrop-bg:           rgb(155, 155, 155) !default;
$modal-backdrop-opacity:      .4 !default;





.speakers-section {
  .container {
    max-width: rem-calc(960);
  }
  .holder {

    @include media('≥tablet') {
      padding: rem-calc(0 80);
    }
  }
}

.speakers-list {
  padding:  rem-calc(0 8);

  @include media('≥tablet') { 
    padding: rem-calc(46 0 23);
  }

  .card-body {
    padding: $card-spacer-x $card-spacer-y ;

    .btn,
    .distance {
      display: none;
    }

    .card-text {
      max-width:  75%;
      margin-bottom: rem-calc(12); 

      @include media('≥tablet') {
        max-width: none;
      }
    }
  }

  .card {
    transition: box-shadow 0.2s linear;

    &:hover {
      box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3);
    }

    >a {
      color: inherit;
      outline: none;
    }
  }
}

.card {
  overflow: hidden;
  border: none;
  font-size: rem-calc(13);
  line-height: rem-calc(20);
  box-shadow: 1px 2px 3px rgba($black, 0.1);
  margin: 0 auto rem-calc(20);

  img {
    width: 100%;
  }

  .card-title {
    margin-bottom: rem-calc(9); 
    // width: 70%;
  }
}

.venue-section {
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 613px;
  padding-top: rem-calc(22);
  line-height: 1.5;
  margin-bottom: 53px;

  @include media('≥tablet') {
    padding-top: rem-calc(55);
    line-height: 1.65;
    min-height: 895px;
  }

  .local {
    position: relative;
    text-align: left; 
    line-height: 1.63;
    padding:  rem-calc(10 20 10 47) ;

    @include media('≥tablet') { 
      padding-left: rem-calc(45);
    }

    &:before {
      content: '';
      position: absolute;
      top: rem-calc(12);
      left: rem-calc(16);
      background: url('images/icon-location.svg') no-repeat;
      width: rem-calc(15);
      height: rem-calc(23);
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  strong {
    font-weight: 500;
  }

  h1 {
    @include media('≥tablet') {
      margin-bottom: rem-calc(35);
    }
  }

  p {
    margin-bottom: rem-calc(18);

    &:last-of-type {
      @include media('≥tablet') {
        margin-bottom: rem-calc(43);
      }
    }
  }

  .btn {
    margin-bottom: rem-calc(20);  
  }
}

.hotels-list {
  padding-top: rem-calc(23);

  @include media('≥tablet') {
    margin-bottom: rem-calc(35);
  }

  .card {
    font-size: rem-calc(10);
    line-height: rem-calc(18);
  }

  .card-body {
    padding:  rem-calc(15 16);

    @include media('≥tablet') {
      padding-bottom: rem-calc(20);
    }
  }

  h2 {
    text-transform: uppercase;
    line-height: rem-calc(30);
    margin-bottom: rem-calc(9);
  }

  .card-link,
  .distance {
    font-size: rem-calc(13);
    line-height: rem-calc(20);
  }

  .distance {
    margin-bottom: rem-calc(14);
  }

  .card-text {
    margin-bottom: rem-calc(13);

    @include media('≥tablet') {
      margin-bottom: rem-calc(38);
    }
  }

  .btn {
    display: block;
    text-align: left; 
  }
}

.travel-section {

  .text-row {
    @include media('≥tablet') {
      margin-bottom: rem-calc(61);
    }
  }
}

.modal-dialog {
  @include media('<900px') {
    padding: 0 rem-calc(15);
  }
}


.modal-content {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  font-size: rem-calc(12);
  border: 0;
  padding: rem-calc(16);

  @include media('≥tablet') {
    flex-direction: row;
    align-items: flex-start;
    padding: rem-calc(35 38);
    line-height: 1.6;
    font-size: rem-calc(13);
  }

  .speakers-name {
    display: block;
    text-transform: uppercase;
    font-size: rem-calc(29);
    line-height: 1.2;
    letter-spacing: 1px;
    font-weight: 300;
    bottom: rem-calc(14);
    margin-bottom: rem-calc(14);

    @include media('≥tablet') {
      font-size: rem-calc(42);
      margin-bottom: rem-calc(20);
    }
  }

  .speakers-photo {
    overflow: hidden;
    width: 257px;
    margin: 0 auto rem-calc(15);

    @include media('≥tablet') {
      margin: rem-calc(0 30 0 0);
      min-width: rem-calc(220);
      width: rem-calc(220);
    }

    img {
      display: inline-block;
      vertical-align: top;
      border-radius: 6px;
      width: 100%;
    }
  }

  .speakers-info {
    text-align: left;

    @include media('≥tablet') {
      width: calc(100% - 250px);
    }
  }

  .close {
    width: rem-calc(22);
    height: rem-calc(22);
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(10);
    color: #000;
    opacity: 1;
    outline: none;

    &:hover {
      color: $green;
    }
  }

  h3 {
    font-size: rem-calc(16);
    margin-bottom: rem-calc(2);
  }

  p {
    margin-bottom: rem-calc(15);

    @include media('<tablet') {
      margin-bottom: rem-calc(18);
    }
  }
}

.modal-open .modal {
  min-height: 0;
  white-space: nowrap;
  text-align: center;
  padding-left: 0 !important;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 1px;
    overflow: hidden;
    margin: rem-calc(0 0 0 5);
  }

  > div {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }
}

.contact-list {
  @extend %listreset;

  li {
    list-style-type: none;
  }
}



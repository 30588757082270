@charset "UTF-8";
.tagcloud, .contact-list, .events-list, ul:not([class]) {
  margin: 0;
  padding: 0;
  list-style: none; }

.clearfix:after, .widget:after, .search-form:after,
.post-password-form:after, .search-form p:after,
.post-password-form p:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

.widget {
  margin-bottom: 1.25rem; }
  .widget select {
    min-width: 150px; }

.search-form label,
.post-password-form label {
  display: block; }

.search-form input,
.post-password-form input {
  float: left;
  margin-right: 5px; }
  @media (min-width: 768px) {
    #sidebar .search-form input, #sidebar
    .post-password-form input {
      width: 100%;
      margin: 0 0 5px; } }
  .search-form input[type='submit'],
  .post-password-form input[type='submit'] {
    margin: 0;
    padding: 0.9375rem 1.375rem; }

.nav-links,
.navigation,
.navigation-comments {
  position: relative;
  vertical-align: middle;
  margin: 0 -3px 1.25rem; }
  .nav-links .screen-reader-text,
  .navigation .screen-reader-text,
  .navigation-comments .screen-reader-text {
    position: absolute;
    left: -99999px; }
  .nav-links .page-numbers,
  .navigation .page-numbers,
  .navigation-comments .page-numbers {
    margin: 0 3px; }
    .nav-links .page-numbers:first-child,
    .navigation .page-numbers:first-child,
    .navigation-comments .page-numbers:first-child {
      margin-left: 0; }
    .nav-links .page-numbers:last-child,
    .navigation .page-numbers:last-child,
    .navigation-comments .page-numbers:last-child {
      margin-right: 0; }
  .nav-links .next,
  .nav-links .prev,
  .navigation .next,
  .navigation .prev,
  .navigation-comments .next,
  .navigation-comments .prev {
    display: inline-block;
    vertical-align: middle;
    max-width: 49%; }
    @media (max-width: 767px) {
      .nav-links .next,
      .nav-links .prev,
      .navigation .next,
      .navigation .prev,
      .navigation-comments .next,
      .navigation-comments .prev {
        font-size: 0; }
        .nav-links .next:after,
        .nav-links .prev:after,
        .navigation .next:after,
        .navigation .prev:after,
        .navigation-comments .next:after,
        .navigation-comments .prev:after {
          font-size: 0.75rem;
          line-height: 1.5;
          content: '»'; } }
  @media (max-width: 767px) {
    .nav-links .prev:after,
    .navigation .prev:after,
    .navigation-comments .prev:after {
      content: '«'; } }

.navigation-single {
  margin-bottom: 1.25rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  color: #000;
  font-size: 1.125rem;
  line-height: 1.8125rem;
  font-weight: bold; }
  @media (min-width: 768px) {
    .navigation-single {
      font-size: 1.5625rem;
      line-height: 2.25rem; } }
  .navigation-single .next,
  .navigation-single .prev {
    max-width: 49%;
    display: flex;
    align-items: center; }
    .navigation-single .next > a,
    .navigation-single .prev > a {
      color: inherit;
      text-decoration: none;
      display: flex;
      flex-direction: column; }
      @media (min-width: 768px) {
        .navigation-single .next > a,
        .navigation-single .prev > a {
          flex-direction: row;
          align-items: center; } }
      .navigation-single .next > a:after,
      .navigation-single .prev > a:after {
        display: none; }
    .navigation-single .next span,
    .navigation-single .prev span {
      padding-top: 0.5rem; }
      @media (min-width: 768px) {
        .navigation-single .next span,
        .navigation-single .prev span {
          padding-top: 0;
          padding-left: 2.875rem; } }
    .navigation-single .next i,
    .navigation-single .prev i {
      font-size: 1rem; }
      @media (min-width: 768px) {
        .navigation-single .next i,
        .navigation-single .prev i {
          font-size: 1.3125rem; } }
  .navigation-single .next a {
    flex-direction: column-reverse;
    align-items: flex-end;
    text-align: right; }
    @media (min-width: 768px) {
      .navigation-single .next a span {
        padding: 0 3rem 0 0; } }
    @media (min-width: 768px) {
      .navigation-single .next a {
        flex-direction: row;
        align-items: center; } }

.comment-form label {
  display: block;
  padding-bottom: 4px; }

.comment-form input,
.comment-form textarea {
  margin: 0 4px 0 0;
  width: 100%; }
  @media (min-width: 768px) {
    .comment-form input,
    .comment-form textarea {
      max-width: 300px; } }
  .comment-form input[type='submit'],
  .comment-form textarea[type='submit'] {
    display: block;
    width: auto; }

.comment-form textarea {
  overflow: auto;
  margin: 0;
  height: 120px;
  min-height: 120px; }

.commentlist {
  margin: 0 0 1.2em; }
  .commentlist .edit-link {
    margin: 0; }
  .commentlist .avatar-holder {
    float: left;
    margin: 0 1.2em 4px 0; }

.commentlist-item .commentlist-item {
  padding: 0; }
  @media (min-width: 768px) {
    .commentlist-item .commentlist-item {
      padding: 0 0 0 2em; } }

.comment,
.commentlist-holder {
  overflow: hidden; }

.commentlist-item .commentlist-item,
.commentlist-item + .commentlist-item {
  padding-top: 20px; }

.widget_calendar th,
.widget_calendar td {
  text-align: center; }

.widget_calendar #prev {
  text-align: left; }

.widget_calendar #next {
  text-align: right; }

#wp-calendar {
  width: 100%; }

.wp-caption {
  max-width: 100%;
  clear: both;
  border: 1px solid #979797;
  padding: 4px;
  margin-bottom: 20px;
  text-align: center; }
  .wp-caption img {
    margin-bottom: 4px; }
  .wp-caption p {
    margin: 0; }

.aligncenter {
  margin: 0 auto 1.25rem; }

img.aligncenter {
  display: block;
  margin: 0 auto; }

.alignleft {
  float: left;
  margin: 0 1.2em 4px 0; }

.alignright {
  float: right;
  margin: 0 0 4px 1.2em; }

.mejs-container {
  margin-bottom: 1.25rem; }

.wp-caption-text,
.gallery,
.alignnone,
.gallery-caption,
.sticky,
.bypostauthor {
  height: auto; }

.gallery .gallery-item {
  padding: 0 10px; }
  .gallery .gallery-item dt {
    float: none;
    width: 100%;
    margin: 0 0 10px;
    padding: 0; }

.gallery-columns-2 .gallery-item {
  max-width: 50%;
  width: 50%; }

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
  width: 33.33%; }

.tagcloud {
  display: flex;
  flex-wrap: wrap; }

.comment-list .comment-body {
  margin-bottom: 20px; }

.comment-author img {
  margin-right: 10px; }

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none; }

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
textarea {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  padding: 0.9375rem 1.375rem;
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: "Roboto Mono", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  box-sizing: border-box;
  border: 0.0625rem solid rgba(151, 151, 151, 0.31);
  border-radius: 0;
  color: #000;
  background: #ffffff;
  border-radius: 4px; }
  @media (min-width: 768px) {
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    input[type='search'],
    input[type='password'],
    input[type='url'],
    textarea {
      padding-left: 1.3125rem;
      padding-right: 0.625rem;
      font-size: 0.875rem; } }
  input[type='text']:not(textarea),
  input[type='tel']:not(textarea),
  input[type='email']:not(textarea),
  input[type='search']:not(textarea),
  input[type='password']:not(textarea),
  input[type='url']:not(textarea),
  textarea:not(textarea) {
    height: 3.1875rem; }
  input[type='text']:focus,
  input[type='tel']:focus,
  input[type='email']:focus,
  input[type='search']:focus,
  input[type='password']:focus,
  input[type='url']:focus,
  textarea:focus {
    outline: none; }
  input[type='text']::-webkit-input-placeholder,
  input[type='tel']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='search']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  input[type='url']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #ddd;
    font-size: 0.75rem;
    line-height: 1.5; }
    @media (min-width: 768px) {
      input[type='text']::-webkit-input-placeholder,
      input[type='tel']::-webkit-input-placeholder,
      input[type='email']::-webkit-input-placeholder,
      input[type='search']::-webkit-input-placeholder,
      input[type='password']::-webkit-input-placeholder,
      input[type='url']::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        font-size: 1rem;
        line-height: 1; } }
  input[type='text']::-moz-placeholder,
  input[type='tel']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='search']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  input[type='url']::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1;
    color: #ddd;
    font-size: 0.75rem;
    line-height: 1.5; }
    @media (min-width: 768px) {
      input[type='text']::-moz-placeholder,
      input[type='tel']::-moz-placeholder,
      input[type='email']::-moz-placeholder,
      input[type='search']::-moz-placeholder,
      input[type='password']::-moz-placeholder,
      input[type='url']::-moz-placeholder,
      textarea::-moz-placeholder {
        font-size: 1rem;
        line-height: 1; } }
  input[type='text']:-moz-placeholder,
  input[type='tel']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='search']:-moz-placeholder,
  input[type='password']:-moz-placeholder,
  input[type='url']:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #ddd;
    font-size: 0.75rem;
    line-height: 1.5; }
    @media (min-width: 768px) {
      input[type='text']:-moz-placeholder,
      input[type='tel']:-moz-placeholder,
      input[type='email']:-moz-placeholder,
      input[type='search']:-moz-placeholder,
      input[type='password']:-moz-placeholder,
      input[type='url']:-moz-placeholder,
      textarea:-moz-placeholder {
        font-size: 1rem;
        line-height: 1; } }
  input[type='text']:-ms-input-placeholder,
  input[type='tel']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='search']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  input[type='url']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #ddd;
    font-size: 0.75rem;
    line-height: 1.5; }
    @media (min-width: 768px) {
      input[type='text']:-ms-input-placeholder,
      input[type='tel']:-ms-input-placeholder,
      input[type='email']:-ms-input-placeholder,
      input[type='search']:-ms-input-placeholder,
      input[type='password']:-ms-input-placeholder,
      input[type='url']:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        font-size: 1rem;
        line-height: 1; } }
  input[type='text'].placeholder,
  input[type='tel'].placeholder,
  input[type='email'].placeholder,
  input[type='search'].placeholder,
  input[type='password'].placeholder,
  input[type='url'].placeholder,
  textarea.placeholder {
    color: #ddd;
    font-size: 0.75rem;
    line-height: 1.5; }
    @media (min-width: 768px) {
      input[type='text'].placeholder,
      input[type='tel'].placeholder,
      input[type='email'].placeholder,
      input[type='search'].placeholder,
      input[type='password'].placeholder,
      input[type='url'].placeholder,
      textarea.placeholder {
        font-size: 1rem;
        line-height: 1; } }

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none; }

textarea {
  resize: vertical;
  vertical-align: top;
  height: 9rem;
  resize: none; }

.form-row {
  margin: 0 0 1.0625rem; }
  @media (min-width: 768px) {
    .form-row {
      margin: 0 0 1.5625rem; } }
  .form-row:last-child {
    margin-bottom: 0; }

.hold-form {
  background-color: #f4f4f4;
  padding: 1.25rem 1.5625rem; }
  @media (min-width: 768px) {
    .hold-form {
      padding: 1.875rem; } }
  .hold-form h3 {
    margin-bottom: 1.875rem; }
  .hold-form label {
    text-transform: uppercase;
    line-height: 1rem;
    font-size: 0.8125rem;
    margin-bottom: 0.75rem; }
    @media (min-width: 768px) {
      .hold-form label {
        line-height: 1.3125rem;
        font-size: 1.0625rem; } }

/* Slick slider styles */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

section.slider-section {
  position: relative;
  margin-bottom: 1.5625rem;
  padding: 0 0.9375rem;
  margin-top: 2px; }
  @media (min-width: 768px) {
    section.slider-section {
      margin-bottom: 3rem;
      margin-right: 3.125rem;
      margin-left: 3.125rem;
      padding: 0; } }
  section.slider-section .slick-slider {
    border-radius: 6px;
    overflow: hidden; }
  section.slider-section .container {
    position: relative; }
  section.slider-section .item {
    height: calc(100vh - 120px);
    min-height: 400px;
    background-repeat: no-repeat;
    background-position: 95% 100%;
    background-size: auto 330px;
    background-color: #1bc66f;
    color: #ffffff;
    position: relative;
    padding: 1rem 0; }
    @media (min-width: 768px) {
      section.slider-section .item {
        padding: 1.3125rem 0 0 2.875rem;
        display: flex;
        background-size: auto 92%; } }
    section.slider-section .item .info-variable {
      padding-top: 9px; }
      @media (min-width: 768px) {
        section.slider-section .item .info-variable {
          padding: 1.125rem 0 0 2.875rem; } }
      section.slider-section .item .info-variable .title {
        display: block;
        text-transform: none;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 1.73;
        margin-bottom: 0.4375rem; }
        @media (min-width: 768px) {
          section.slider-section .item .info-variable .title {
            font-size: 3.25rem;
            margin-bottom: 0.75rem; } }
      section.slider-section .item .info-variable .txt {
        display: block;
        line-height: 1.3; }
        @media (min-width: 768px) {
          section.slider-section .item .info-variable .txt {
            margin-bottom: 0.4375rem;
            line-height: 1; } }
  section.slider-section .info-constant {
    position: absolute;
    z-index: 999;
    left: 35px;
    top: 85px;
    right: auto;
    max-width: 70%;
    letter-spacing: 0.53px;
    font-size: 0.9375rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 300;
    pointer-events: auto; }
    @media (min-width: 768px) {
      section.slider-section .info-constant {
        font-size: 1.25rem;
        top: 120px;
        left: 63px;
        line-height: 1.2; } }
    section.slider-section .info-constant .sub-inf {
      display: block;
      font-size: 0.6875rem;
      line-height: 1.8; }
      @media (min-width: 768px) {
        section.slider-section .info-constant .sub-inf {
          font-size: 1.25rem;
          line-height: 1.4; } }
  section.slider-section .container {
    padding-left: 1.125rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 30;
    pointer-events: none; }
  section.slider-section .btn-toolbar {
    padding-top: 1.5rem;
    flex-direction: column;
    align-items: flex-start !important; }
    @media (min-width: 768px) {
      section.slider-section .btn-toolbar {
        padding-top: 2.375rem;
        flex-direction: row;
        align-items: center !important; } }
    section.slider-section .btn-toolbar .btn-group {
      margin-bottom: 10px; }
      section.slider-section .btn-toolbar .btn-group + .btn-group {
        margin-top: 1rem; }
        @media (min-width: 768px) {
          section.slider-section .btn-toolbar .btn-group + .btn-group {
            margin-top: 0;
            margin-left: 1.25rem; } }
      section.slider-section .btn-toolbar .btn-group .btn {
        font-size: 1rem;
        white-space: normal; }
  section.slider-section .btn-light {
    color: #1bc66f;
    padding: 0.6875rem 1.1875rem; }
  section.slider-section .presented {
    border-radius: 0.25rem;
    overflow: hidden;
    position: absolute;
    bottom: 11px;
    left: 30px; }
    @media (min-width: 768px) {
      section.slider-section .presented {
        bottom: 31px;
        left: 3.375rem; } }
    section.slider-section .presented a {
      outline: none; }
      section.slider-section .presented a:hover {
        opacity: .7; }

.speakers-section .container {
  max-width: 60rem; }

@media (min-width: 768px) {
  .speakers-section .holder {
    padding: 0 5rem; } }

.speakers-list {
  padding: 0 0.5rem; }
  @media (min-width: 768px) {
    .speakers-list {
      padding: 2.875rem 0 1.4375rem; } }
  .speakers-list .card-body {
    padding: 0.625rem 0.9375rem; }
    .speakers-list .card-body .btn,
    .speakers-list .card-body .distance {
      display: none; }
    .speakers-list .card-body .card-text {
      max-width: 75%;
      margin-bottom: 0.75rem; }
      @media (min-width: 768px) {
        .speakers-list .card-body .card-text {
          max-width: none; } }
  .speakers-list .card {
    transition: box-shadow 0.2s linear; }
    .speakers-list .card:hover {
      box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3); }
    .speakers-list .card > a {
      color: inherit;
      outline: none; }

.card {
  overflow: hidden;
  border: none;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto 1.25rem; }
  .card img {
    width: 100%; }
  .card .card-title {
    margin-bottom: 0.5625rem; }

.venue-section {
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 613px;
  padding-top: 1.375rem;
  line-height: 1.5;
  margin-bottom: 53px; }
  @media (min-width: 768px) {
    .venue-section {
      padding-top: 3.4375rem;
      line-height: 1.65;
      min-height: 895px; } }
  .venue-section .local {
    position: relative;
    text-align: left;
    line-height: 1.63;
    padding: 0.625rem 1.25rem 0.625rem 2.9375rem; }
    @media (min-width: 768px) {
      .venue-section .local {
        padding-left: 2.8125rem; } }
    .venue-section .local:before {
      content: '';
      position: absolute;
      top: 0.75rem;
      left: 1rem;
      background: url("images/icon-location.svg") no-repeat;
      width: 0.9375rem;
      height: 1.4375rem;
      background-position: 50% 50%;
      background-size: cover; }
  .venue-section strong {
    font-weight: 500; }
  @media (min-width: 768px) {
    .venue-section h1 {
      margin-bottom: 2.1875rem; } }
  .venue-section p {
    margin-bottom: 1.125rem; }
    @media (min-width: 768px) {
      .venue-section p:last-of-type {
        margin-bottom: 2.6875rem; } }
  .venue-section .btn {
    margin-bottom: 1.25rem; }

.hotels-list {
  padding-top: 1.4375rem; }
  @media (min-width: 768px) {
    .hotels-list {
      margin-bottom: 2.1875rem; } }
  .hotels-list .card {
    font-size: 0.625rem;
    line-height: 1.125rem; }
  .hotels-list .card-body {
    padding: 0.9375rem 1rem; }
    @media (min-width: 768px) {
      .hotels-list .card-body {
        padding-bottom: 1.25rem; } }
  .hotels-list h2 {
    text-transform: uppercase;
    line-height: 1.875rem;
    margin-bottom: 0.5625rem; }
  .hotels-list .card-link,
  .hotels-list .distance {
    font-size: 0.8125rem;
    line-height: 1.25rem; }
  .hotels-list .distance {
    margin-bottom: 0.875rem; }
  .hotels-list .card-text {
    margin-bottom: 0.8125rem; }
    @media (min-width: 768px) {
      .hotels-list .card-text {
        margin-bottom: 2.375rem; } }
  .hotels-list .btn {
    display: block;
    text-align: left; }

@media (min-width: 768px) {
  .travel-section .text-row {
    margin-bottom: 3.8125rem; } }

@media (max-width: 899px) {
  .modal-dialog {
    padding: 0 0.9375rem; } }

.modal-content {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  border: 0;
  padding: 1rem; }
  @media (min-width: 768px) {
    .modal-content {
      flex-direction: row;
      align-items: flex-start;
      padding: 2.1875rem 2.375rem;
      line-height: 1.6;
      font-size: 0.8125rem; } }
  .modal-content .speakers-name {
    display: block;
    text-transform: uppercase;
    font-size: 1.8125rem;
    line-height: 1.2;
    letter-spacing: 1px;
    font-weight: 300;
    bottom: 0.875rem;
    margin-bottom: 0.875rem; }
    @media (min-width: 768px) {
      .modal-content .speakers-name {
        font-size: 2.625rem;
        margin-bottom: 1.25rem; } }
  .modal-content .speakers-photo {
    overflow: hidden;
    width: 257px;
    margin: 0 auto 0.9375rem; }
    @media (min-width: 768px) {
      .modal-content .speakers-photo {
        margin: 0 1.875rem 0 0;
        min-width: 13.75rem;
        width: 13.75rem; } }
    .modal-content .speakers-photo img {
      display: inline-block;
      vertical-align: top;
      border-radius: 6px;
      width: 100%; }
  .modal-content .speakers-info {
    text-align: left; }
    @media (min-width: 768px) {
      .modal-content .speakers-info {
        width: calc(100% - 250px); } }
  .modal-content .close {
    width: 1.375rem;
    height: 1.375rem;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    color: #000;
    opacity: 1;
    outline: none; }
    .modal-content .close:hover {
      color: #1bc66f; }
  .modal-content h3 {
    font-size: 1rem;
    margin-bottom: 0.125rem; }
  .modal-content p {
    margin-bottom: 0.9375rem; }
    @media (max-width: 767px) {
      .modal-content p {
        margin-bottom: 1.125rem; } }

.modal-open .modal {
  min-height: 0;
  white-space: nowrap;
  text-align: center;
  padding-left: 0 !important; }
  .modal-open .modal:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 1px;
    overflow: hidden;
    margin: 0 0 0 0.3125rem; }
  .modal-open .modal > div {
    display: inline-block;
    vertical-align: middle;
    white-space: normal; }

.contact-list li {
  list-style-type: none; }

#header {
  background: #ffffff; }
  @media (min-width: 768px) {
    #header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100; } }
  #header.fixed-position {
    box-shadow: 0 0 10px 0 #A6A6A6; }

.navbar {
  text-transform: uppercase;
  font: 15px/1 "Roboto Mono", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  letter-spacing: 1px;
  padding: 0.6875rem 0 0.75rem 0.3125rem; }
  @media (min-width: 768px) {
    .navbar {
      padding: 1.375rem 0.5rem; } }
  .navbar .navbar-nav {
    padding: 1.25rem 0.6875rem 0 0;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .navbar .navbar-nav {
        padding-top: 0; } }
    .navbar .navbar-nav .nav-link {
      padding: 0; }
      .navbar .navbar-nav .nav-link:focus {
        color: #1bc66f;
        outline: none; }
      .navbar .navbar-nav .nav-link.anchor-active {
        color: #128349; }
  .navbar .nav-item {
    padding: 7px 0;
    text-align: center; }
    @media (min-width: 768px) {
      .navbar .nav-item {
        padding: 0.125rem 0 0.125rem 2.1875rem;
        text-align: right; } }
  .navbar .navbar-brand {
    min-width: 150px; }
    .navbar .navbar-brand img {
      display: inline-block;
      vertical-align: top; }

.navbar-toggler {
  border: 0;
  outline: none;
  opacity: 1;
  padding: 0.3125rem 0; }
  .navbar-toggler:focus {
    outline: none; }
  .navbar-toggler.collapsed {
    opacity: 1; }
    .navbar-toggler.collapsed:hover {
      opacity: 0.7; }

.navbar-toggler-icon {
  width: 1.625rem; }

body {
  min-width: 320px; }
  @media (min-width: 768px) {
    body {
      font-size: 1rem;
      line-height: 1.63; } }

p {
  margin-bottom: 1.375rem; }
  @media (min-width: 768px) {
    p {
      margin-bottom: 1.625rem; } }

img {
  max-width: 100%;
  height: auto; }

h1 {
  text-transform: uppercase;
  font-weight: 300;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.125rem;
  font-size: 1.75rem;
  margin-bottom: 1.5625rem; }
  @media (min-width: 768px) {
    h1 {
      font-size: 3.125rem;
      margin-bottom: 1.375rem; } }
  h1 span {
    display: inline-block;
    vertical-align: top;
    position: relative; }
    h1 span:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 9999px;
      background: #979797;
      height: 0.0625rem;
      margin-left: 1.1875rem; }
      @media (min-width: 768px) {
        h1 span:after {
          margin-left: 1.875rem; } }

.h1 {
  font-weight: 500;
  font-size: 1.875rem; }
  @media (min-width: 768px) {
    .h1 {
      line-height: 1.25;
      font-size: 3.25rem; } }

h3,
.h3 {
  font-size: 1rem;
  margin-bottom: 1.1875rem; }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 1.625rem;
      margin-bottom: 1.75rem; } }

h2,
.h2 {
  line-height: 1.625rem;
  font-weight: 300;
  margin: 0 0 0.625rem; }

a:hover {
  text-decoration: none; }

.btn {
  border-radius: 0;
  font-size: 1rem;
  padding: 0.6875rem 1.375rem;
  white-space: normal; }

.container {
  max-width: 910px; }

#wrapper {
  width: 100%;
  position: relative;
  overflow: hidden; }
  @media (min-width: 768px) {
    #wrapper {
      padding-top: 4.375rem; } }

.holder {
  padding: 0 0.5rem; }
  @media (min-width: 768px) {
    .holder {
      padding: 0 3.5rem; } }

@media (max-width: 767px) {
  .text-section {
    line-height: 1.9; } }

@media (min-width: 768px) {
  .text-section h3 {
    font-size: 1.4375rem; } }

@media (min-width: 768px) {
  .text-section p {
    margin-bottom: 1.5625rem; } }

.text-section .img-full {
  margin: 0 -0.625rem; }
  @media (min-width: 768px) {
    .text-section .img-full {
      margin: 0 -1.5625rem; } }
  .text-section .img-full img {
    width: 100%; }

@media (min-width: 768px) {
  .sponsors-list {
    padding: 1.5625rem 5rem 0.375rem; } }

.sponsors-list .sponsor {
  display: block;
  margin-bottom: 1.1875rem;
  text-align: center;
  transition: all 0.2s linear; }
  .sponsors-list .sponsor:hover {
    opacity: 0.75; }

.sponsors-list img {
  display: inline-block;
  vertical-align: top; }

.sponsors-list .btn {
  display: block;
  width: 100%; }

.agenda-section .date {
  display: block;
  font-weight: 500;
  font-size: 1.25rem;
  font-size: 1rem;
  margin-bottom: 0.25rem; }
  @media (min-width: 992px) {
    .agenda-section .date {
      margin-bottom: 0.625rem;
      font-size: 1.625rem; } }

.agenda-box {
  padding-top: 0.5rem; }
  @media (min-width: 768px) {
    .agenda-box {
      padding-top: 0; } }

.events-list {
  margin-bottom: 0.625rem; }
  @media (min-width: 768px) {
    .events-list {
      margin-bottom: 1.5rem; } }
  .events-list li {
    border-bottom: 1px solid #f4f4f4;
    padding: 0.5rem 0; }
    @media (min-width: 768px) {
      .events-list li {
        padding: 0.75rem 0; } }
    .events-list li:last-child {
      border: 0; }
  .events-list .event {
    display: block; }

.info-box {
  background: #f8f8f8;
  padding: 1.375rem 1rem;
  font-size: 0.75rem;
  margin-bottom: 0.75rem; }
  @media (min-width: 992px) {
    .info-box {
      font-size: 0.8125rem;
      padding: 2.625rem 2.125rem;
      line-height: 1.6; } }
  .info-box .info-title {
    display: block;
    font-size: 0.75rem;
    padding: 0 15px;
    margin-bottom: 1.1875rem; }
    @media (min-width: 768px) {
      .info-box .info-title {
        margin-bottom: 2rem;
        font-size: 1rem; } }
  .info-box p {
    margin-bottom: 1.125rem; }
    @media (min-width: 768px) {
      .info-box p {
        margin-bottom: 1.375rem; } }
  .info-box a {
    display: inline-block;
    vertical-align: top; }
    @media (min-width: 768px) {
      .info-box a {
        margin-bottom: 0.5625rem;
        font-size: 1rem; } }

.section {
  margin-bottom: 3.3125rem; }
  @media (min-width: 768px) {
    .section {
      margin-bottom: 3.9375rem; } }

.jumbotron {
  padding: 1rem 1.625rem;
  color: #ffffff;
  margin: 0 0 1.6875rem;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  line-height: 2.5rem; }
  @media (min-width: 768px) {
    .jumbotron {
      margin: 0 0 3.4375rem;
      padding: 2rem 1.625rem 2.5rem; } }

ul:not([class]) li {
  position: relative;
  padding-left: 1.5rem; }
  ul:not([class]) li:before {
    content: '';
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #4a4a4a;
    position: absolute;
    top: 0.6875rem;
    left: 0; }

.hold-benefits {
  padding: 0 0 1.5rem;
  padding-left: 0.8125rem; }
  @media (min-width: 768px) {
    .hold-benefits {
      margin-bottom: 30px; } }

.author {
  display: block;
  margin-bottom: 2.8125rem; }

#footer {
  font-size: 0.625rem;
  line-height: 1.8; }

section.slider-section {
	position: relative;
	margin-bottom: rem-calc(25);  
	padding:  0 rem-calc(15);
	margin-top: 2px;

	@include media('≥tablet') { 
		margin-bottom: rem-calc(48);
		margin-right: rem-calc(50);  
		margin-left: rem-calc(50);  
		padding: 0;
	}

	.slick-slider {
		border-radius: 6px;
		overflow: hidden; 
	}

	.container {
		position: relative;
	}

	.item {
		height: calc(100vh - 120px);
		min-height: 400px;
		background-repeat: no-repeat;
		background-position: 95% 100%;
		background-size: auto 330px;
		background-color: $green;
		color: $white;
		position: relative;
		padding: rem-calc(16px 0);

		@include media(≥tablet) {
			padding: rem-calc(21 0 0 46);
			display: flex;
			background-size: auto 92%;
		}

		.info-variable {
			padding-top: 9px;

			@include media('≥tablet') {
				padding: rem-calc(18 0 0 46);
			}

			.title {
				display: block;
				text-transform: none;
				font-weight: 500;
				font-size: rem-calc(40);
				line-height: 1.73;
				margin-bottom: rem-calc(7);

				@include media(≥tablet) {
					font-size: rem-calc(52);
					margin-bottom: rem-calc(12);
				}
			}

			.txt {
				display: block;
				line-height: 1.3;

				@include media(≥tablet) {
					margin-bottom: rem-calc(7);
					line-height: 1;
				}
			}
		}
	}

	.info-constant {
		position: absolute;
		z-index: 999;
		left: 35px;
		top: 85px;
		right: auto;
		max-width: 70%;
		letter-spacing: 0.53px;
		font-size: rem-calc(15);
		line-height: 1.2;
		text-transform: uppercase;
		color: $white;
		font-weight: 300;
		pointer-events: auto;

		@include media(≥tablet) {
			font-size: rem-calc(20);
			top: 120px;
			left: 63px;
			line-height: 1.2;
		}

		.sub-inf {
			display: block;
			font-size: rem-calc(11);
			line-height: 1.8;

			@include media(≥tablet) {
				font-size: rem-calc(20);
				line-height: 1.4;
			}
		}
	}

	.container {
		padding-left: rem-calc(18);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 30;
		pointer-events: none;
	}

	.btn-toolbar {
		padding-top: rem-calc(24);
		flex-direction: column;
		align-items: flex-start !important;

		@include media(≥tablet) {
			padding-top: rem-calc(38);
			flex-direction: row;
			align-items: center !important;
		}

		.btn-group {
			margin-bottom: 10px;

			&+.btn-group {
				margin-top: rem-calc(16);

				@include media(≥tablet) {
					margin-top: 0;
					margin-left: rem-calc(20);
				}
			}
			.btn {
				font-size: rem-calc(16);
				white-space: normal;
			}
		}
	}

	.btn-light {
		color: $green;
		padding: rem-calc(11 19);
	}

	.presented {
		border-radius: rem-calc(4);
		overflow: hidden;
		position: absolute;
		bottom: 11px;
		left: 30px;

		@include media(≥tablet) {
			bottom: 31px;
			left: rem-calc(54);
		}

		a {
			outline: none;

			&:hover {
				opacity: .7;
			}
		}
	}
}
#header {
	background: $white;

	@include media('≥tablet') {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}

	&.fixed-position {
		box-shadow: 0 0 10px 0 #A6A6A6;;
	}
}

.navbar {
	text-transform: uppercase;
	font: 15px/1 $font-family-base;
	letter-spacing: 1px;
	padding: rem-calc(11) 0 rem-calc(12) rem-calc(5);

	@include media('≥tablet') {	
		padding: rem-calc(22) rem-calc(8);
	}

	.navbar-nav {
		padding: rem-calc(20 11 0 0);
		flex-wrap: wrap;

		@include media('≥tablet') {
			padding-top: 0;
		}

		.nav-link {
			padding: 0;

			
			&:focus {
				color: #1bc66f;
				outline: none;
			}
			&.anchor-active {
				color: #128349;
			}
		}
	}

	.nav-item {
		padding: 7px 0;
		text-align: center;

		@include media('≥tablet') {
			padding: rem-calc(2 0 2 35);
			text-align: right;
		}


	}


	.navbar-brand {
		min-width: 150px;

		img {
			display: inline-block;
			vertical-align: top;
		}
	}
}

.navbar-toggler {
	border: 0;
	outline: none;
	opacity: 1;
	padding: rem-calc(5 0);

	&:focus {
		outline: none;
	}


	&.collapsed {
		opacity: 1;

		&:hover {
			opacity: 0.7;
		}
	}
}

.navbar-toggler-icon {
	width: rem-calc(26);
}

body {
	min-width: $min-width-container;

	@include media('≥tablet') {
		font-size: rem-calc(16);
		line-height: 1.63;
	}
}

p {
	margin-bottom:  rem-calc(22);

	@include media('≥tablet') {
		margin-bottom: rem-calc(26);
	}
}

img {
	max-width: 100%;
	height: auto;
}

h1 {
	text-transform: uppercase;
	font-weight: 300;
	position: relative;
	overflow: hidden;
	letter-spacing: rem-calc(2);
	font-size: rem-calc(28);
	margin-bottom: rem-calc(25);

	@include media('≥tablet') {
		font-size: rem-calc(50);
		margin-bottom: rem-calc(22);
	}

	span {
		display: inline-block;
		vertical-align: top;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 100%;
			width: 9999px;
			background: #979797;
			height: rem-calc(1);
			margin-left: rem-calc(19);

			@include media('≥tablet') { 
				margin-left: rem-calc(30);
			}
		}
	}

}

.h1 {
	font-weight: 500; 
	font-size: rem-calc(30);

	@include media('≥tablet') {
		line-height: 1.25;
		font-size: rem-calc(52);
	}


}

h3,
.h3 {
	font-size: $h3-font-size;
	margin-bottom: rem-calc(19);

	@include media('≥tablet') {
		font-size: rem-calc(26);
		margin-bottom: rem-calc(28);
	}
}

h2,
.h2 {
	line-height: rem-calc(26);
	font-weight: 300;
	margin: 0 0 rem-calc(10);

	@include media('≥tablet') {

	}
}

a {
	&:hover{
		text-decoration: none;
	}
}

.btn {
	border-radius: 0;
	font-size: rem-calc(16);
	padding:  rem-calc(11 22);
	white-space: normal;
}


.container {
	max-width: $max-width-container;
}

#wrapper {
	width: 100%;
	position: relative;
	overflow: hidden;

	@include media('≥tablet') {
		padding-top: rem-calc(70);
	}
}


.holder {
	padding:  0 rem-calc(8);

	@include media('≥tablet') {
		padding: 0 rem-calc(56);
	}
}

.text-section{
	@include media('<tablet') { 
		line-height: 1.9; 
	}

	h3 {
		@include media('≥tablet') {
			font-size: rem-calc(23);
		}
	}

	p {
		@include media('≥tablet') {
			margin-bottom: rem-calc(25px);
		}
	}

	.img-full {
		margin: rem-calc(0 -10px);

		@include media('≥tablet') {
			margin: rem-calc(0 -25);
		}

		img {
			width: 100%;
		}
	}
}

.sponsors-section,
.travel-section {

	// @include media('≥tablet') {
	// 	padding: rem-calc(0);
	// }
}

.sponsors-list {

	@include media('≥tablet') {
		padding: rem-calc(25 80 6);
	}

	.sponsor {
		display: block;
		margin-bottom: rem-calc(19);
		text-align: center;
		transition: all 0.2s linear;

		&:hover {
			opacity: 0.75;
		}
	}

	img {
		display: inline-block;
		vertical-align: top;
	}

	.btn {
		display: block;
		width: 100%;
	}
}

.agenda-section {
	.date {
		display: block;
		font-weight: 500;
		font-size: rem-calc(20);
		font-size: rem-calc(16);
		margin-bottom: rem-calc(4);

		@include media('≥desktop') {
			margin-bottom: rem-calc(10);
			font-size: rem-calc(26);
		}
	}
}

.agenda-box {
	padding-top: rem-calc(8); 

	@include media('≥tablet') {
		padding-top: 0;
	}
}

.events-list {
	@extend %listreset;
	margin-bottom:  rem-calc(10);

	@include media('≥tablet') { 
		margin-bottom: rem-calc(24);
	}

	li {
		border-bottom: 1px solid #f4f4f4;
		padding: rem-calc(8 0);

		@include media('≥tablet') { 
			padding: rem-calc(12 0);
		}

		&:last-child {
			border: 0;
		}
	}

	.event {
		display: block;
	}
}

.info-box {
	background: #f8f8f8;
	padding:  rem-calc(22 16);
	font-size: rem-calc(12);
	margin-bottom: rem-calc(12);

	@include media('≥desktop') {
		font-size: rem-calc(13);
		padding: rem-calc(42 34);
		line-height: 1.6;
	}

	.info-title {
		display: block;
		font-size: rem-calc(12);
		padding:  0 15px;
		margin-bottom: rem-calc(19);

		@include media('≥tablet') { 
			margin-bottom: rem-calc(32);
			font-size: rem-calc(16);
		}
	}

	p {
		margin-bottom: rem-calc(18);

		@include media('≥tablet') { 
			margin-bottom: rem-calc(22);
		}
	}

	a {
		display: inline-block;
		vertical-align: top;

		@include media('≥tablet') { 
			margin-bottom: rem-calc(9);
			font-size: rem-calc(16);
		}
	}
}



.section {
	margin-bottom: rem-calc(53);

	@include media('≥tablet') {
		margin-bottom: rem-calc(63);
	}
}



.jumbotron {
	padding: rem-calc(16 26);
	color: $white;
	margin: rem-calc(0 0 27);
	box-shadow: 1px 2px 3px rgba($black, 0.1);
	line-height: rem-calc(40);

	@include media('≥tablet') {
		margin: rem-calc(0 0 55);
		padding: rem-calc(32 26 40);
	}
}

ul:not([class]) {
	@extend %listreset;

	li {
		position: relative;
		padding-left: rem-calc(24);

		&:before {
			content: '';
			display: block;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: $brown;
			position: absolute;
			top: rem-calc(11);
			left: 0;
		}
	}
}

.hold-benefits {
	padding: 0 0 1.5rem;
	padding-left: rem-calc(13);

	@include media('≥tablet') {
		margin-bottom: 30px;
	}
}

.author {
	display: block;
	margin-bottom: rem-calc(45);
}